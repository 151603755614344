import UserPopupArrow from "@assets/icons/userPopupArrow";
import { Select } from "antd";
import classNames from "classnames";
import { useMemo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  getDefaultCountry,
  formatOptionLabel,
  Option,
  Routes,
  RoutesMarket,
} from "../utils";
import bg from "@assets/img/menu-bg.svg";
import styles from "./styles.module.scss";
import { useOutsideClick } from "src/utils/useOutsideClick";
import Canada from "@assets/icons/Canada.png";
import Mexico from "@assets/icons/Mexico.png";
import tik from "@assets/icons/tiktok.png";
import Shopify from "@assets/icons/Shopify.png";
import US from "@assets/icons/US.png";

interface NavigationProps {
  setIsOpenMenu: (isOpen: boolean) => void;
  customTitles?: string[];
}
const customCountries = [
  { src: US, alt: "US", label: "United States" },
  { src: Canada, alt: "Canada", label: "Canada" },
  { src: Mexico, alt: "Mexico", label: "Mexico" },
  { src: Shopify, alt: "Shopify", label: "www.shopify.com" },
  { src: tik, alt: "tiktok", label: "www.tiktok.com" },
];
export const Navigation = ({
  setIsOpenMenu,
  customTitles,
}: NavigationProps) => {
  const options = useMemo(() => {
    return customCountries.map((country) => ({
      value: country.alt,
      label: country.label.startsWith("www")
        ? country.label.charAt(0).toLowerCase() + country.label.slice(1)
        : country.label.charAt(0).toUpperCase() + country.label.slice(1),
      src: country.src,
    }));
  }, []);

  const defaultCountry = getDefaultCountry(options);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<{
    value: string;
    label: React.ReactNode;
  }>({
    value: defaultCountry?.value,
    label: formatOptionLabel(defaultCountry),
  });

  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    setSelectedCountry(value);
  };

  const buttonRef = useRef(null);

  const menuRef = useOutsideClick((event) => {
    if (
      (event.target as Element).closest(".ant-select-dropdown") ||
      event.target === buttonRef.current
    ) {
      return;
    }
    setIsOpenMenu(false);
    setIsMenuOpen(false);
  });

  return (
    <div className={classNames(styles.menu, isMenuOpen && styles.open)}>
      <img className={styles.bg} src={bg} alt="bg" />
      <div ref={menuRef} className={styles.button__container}>
        <button
          className={styles.burger}
          ref={buttonRef}
          onClick={() => {
            setIsOpenMenu(!isMenuOpen);
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <span></span>
        </button>
        {isMenuOpen && <div className={styles.bg}></div>}
      </div>
      {isMenuOpen && (
        <div ref={menuRef} className={styles.menu__container}>
          {!customTitles && (
            <Select
              value={selectedCountry}
              onChange={handleChange}
              labelInValue
              optionLabelProp="label"
              className={styles.select}
            >
              {options.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  label={formatOptionLabel(option)}
                >
                  {formatOptionLabel(option)}
                </Option>
              ))}
            </Select>
          )}
          <div className={styles.routes}>
            {(customTitles ? RoutesMarket : Routes).map((route) => (
              <NavLink
                to={route.link}
                key={route.title}
                className={({ isActive }) =>
                  classNames(styles.route, {
                    [styles.active]: isActive,
                  })
                }
                onClick={() => {
                  setIsOpenMenu(!isMenuOpen);
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                {route.title}
                <UserPopupArrow />
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
