import { create } from "zustand";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { initializeCalendarData } from "src/utils/initialCalendarData";
import { WidgetMetricKey } from "@services/math/math.service";
import { WIDGET_METRIC_KEYS } from "@services/math/math.const";

export interface IDashboardState {
  widgets: WidgetMetricKey[];
  availableWidgets: WidgetMetricKey[];
  modalWidgets: IWidget[];
  selectedWidgets: WidgetMetricKey[];
  selectedWidgetsCOO: WidgetMetricKey[];
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  compareType: "raw" | "percent";
  setCompareType: (type: "raw" | "percent") => void;
  setWidgets: (widgets: WidgetMetricKey[]) => void;
  setAvailableWidgets: (widgets: WidgetMetricKey[]) => void;
  setModalWidgets: (widgets: IWidget[]) => void;
  setSelectedWidgets: (widgets: WidgetMetricKey[]) => void;
  setSelectedWidgetsCOO: (widgets: WidgetMetricKey[]) => void;
  setDateRange: (range: { startDate: Date; endDate: Date }) => void;
  updateWidgets: (
    widgets: WidgetMetricKey[],
    availableWidgets: WidgetMetricKey[],
  ) => void;
  reset: () => void;
}

const { today, monday } = initializeCalendarData();

export const defaultWidgets = [
  "salesTotal",
  "adsClicks",
  "adsSales",
  "adsSpend",
  "adsAcos",
  "adsRoas",
  "adsCpc",
  "adsCvr",
  "adsCtr",
  "adsUnitsSold",
];

export const defaultWidgetsCOO: WidgetMetricKey[] = [
  "fbaInStockRate",
  "shippingCosts",
  "safetyStock",
  "Available Capital",
  "Frozen Capital",
  "Lead Time",
  "forecastingAccuracy",
  "contributionMargin",
  "DOI",
  "storageCosts",
  "lostSalesCfo",
  "fbtInStockRate",
  "3plInStockRate",
];

export const useDashboardStore = create<IDashboardState>((set) => ({
  widgets: [],
  availableWidgets: WIDGET_METRIC_KEYS,
  modalWidgets: [],
  selectedWidgets: defaultWidgets,
  selectedWidgetsCOO: defaultWidgetsCOO,
  dateRange: {
    endDate: today,
    startDate: monday,
  },
  compareType: "raw",
  setCompareType: (type: "raw" | "percent") => set({ compareType: type }),
  setWidgets: (widgets: WidgetMetricKey[]) => set({ widgets }),
  setAvailableWidgets: (availableWidgets: WidgetMetricKey[]) => {
    if (!Array.isArray(availableWidgets)) {
      return;
    }
    set({ availableWidgets });
  },
  setModalWidgets: (modalWidgets: IWidget[]) => set({ modalWidgets }),
  setSelectedWidgets: (selectedWidgets: WidgetMetricKey[]) =>
    set({ selectedWidgets }),
  setSelectedWidgetsCOO: (selectedWidgetsCOO: WidgetMetricKey[]) =>
    set({ selectedWidgetsCOO }),
  setDateRange: (range: { startDate: Date; endDate: Date }) =>
    set({ dateRange: range }),
  updateWidgets: (
    widgets: WidgetMetricKey[],
    availableWidgets: WidgetMetricKey[],
  ) => {
    set({ widgets, availableWidgets });
  },
  reset: () =>
    set({
      widgets: [],
      availableWidgets: [],
      modalWidgets: [],
      dateRange: { startDate: new Date(), endDate: new Date() },
    }),
}));
