import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useEffect, useState } from "react";
import MetricBuilder from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder";
import { useCanvaStore } from "src/store/canva/canva.state";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WidgetClass } from "@services/widget.service";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { WidgetMetricKey } from "@services/math/math.service";
import { Spin } from "antd";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";
import styles from "./styles.module.scss";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { WIDGET_METRIC_GROUPS } from "@services/math/math.const";

interface IDefaultViewProps {
  choosenWidgets: WidgetMetricKey[];
  customTitles?: string[];
}

export const DefaultView: React.FC<IDefaultViewProps> = ({
  choosenWidgets,
  customTitles,
}) => {
  const { dateRange, compareType } = useDashboardStore();
  const { compareWith } = useAdsAnaliticsStore();
  const { startDate, endDate } = dateRange;
  const [widgets, setWidgets] = useState<IWidget[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { isWhatIf } = useCanvaStore();

  const cooKeys =
    WIDGET_METRIC_GROUPS.find((group) => group.title === "COO")?.keys || [];
  const cooLabels = cooKeys.map(
    (key) => WIDGET_METRICS_CONFIG[key]?.label || key,
  );

  const formatDifference = (difference: string): string => {
    if (difference.startsWith("+") || difference.startsWith("-")) {
      return difference;
    }
    return `+${difference}`;
  };

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const { startDate: compareStartDate, endDate: compareEndDate } =
        compareWith;
      const compareStartDateString = startOfWeek(subWeeks(compareStartDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDateString = endOfWeek(subWeeks(compareEndDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        choosenWidgets,
        startDateString,
        endDateString,
        compareStartDateString,
        compareEndDateString,
        compareType,
      );

      let updatedWidgets = response;

      if (customTitles && customTitles.length > 0) {
        updatedWidgets = choosenWidgets
          .map((key) => {
            const widget = response.find((w) => w.id === key);

            if (!widget) return null;
            const label = WIDGET_METRICS_CONFIG[key]?.label || key;
            return {
              ...widget,
              title: label.trim(),
              name: label.trim(),
              difference: formatDifference(widget.difference),
            };
          })
          .filter(Boolean)
          .slice(0, 10);
      } else {
        updatedWidgets = response.map((widget) => ({
          ...widget,
          difference: formatDifference(widget.difference),
        }));
      }

      setWidgets(updatedWidgets);
      setIsLoading(false);
    };
    fetchWidgets();
  }, [choosenWidgets, dateRange, compareWith, compareType, customTitles]);

  return isWhatIf ? (
    <div className={styles.optimisation}>
      <MetricBuilder
        metricPerRow={5}
        useConfigButton
        hideSettings
        hideLine
        customTitles={customTitles}
      />
    </div>
  ) : (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading}>
          <Spin />
        </div>
      ) : (
        <div className={styles.widgets}>
          {widgets.map((widget, index) => (
            <Widget
              key={index}
              widget={widget}
              additionalClass={styles.widget}
            />
          ))}
        </div>
      )}
    </div>
  );
};
