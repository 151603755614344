import { useState } from "react";
import styles from "./styles.module.scss";
import { Switch } from "antd";
import Setting from "@assets/icons/setting";
import { AdvancedView } from "./AdvancedView";
import { DefaultView } from "./DefaultView";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { WidgetMetricKey } from "@services/math/math.service";
import { SettingsModal } from "./SettingsBigModal";
import { changeElementSize } from "@pages/CanvasEditor/Canva";

const MAXIMUM_WIDGETS = 10;

interface ExecutiveSummaryProps {
  hideSwitch?: boolean;
  customTitles?: string[];
  id?: string;
}

export const ExecutiveSummary: React.FC<ExecutiveSummaryProps> = ({
  hideSwitch = false,
  customTitles,
  id,
}) => {
  const [isAdvancedView, setIsAdvancedView] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    selectedWidgets,
    selectedWidgetsCOO,
    setSelectedWidgets,
    setSelectedWidgetsCOO,
  } = useDashboardStore();

  const initialWidgets = customTitles ? selectedWidgetsCOO : selectedWidgets;
  const [choosedWidgets, setChoosedWidgets] =
    useState<WidgetMetricKey[]>(initialWidgets);

  const onApply = () => {
    if (customTitles) {
      setSelectedWidgetsCOO(choosedWidgets);
    } else {
      setSelectedWidgets(choosedWidgets);
    }
    setIsOpenModal(false);
  };

  const allowedGroups = customTitles ? ["COO"] : undefined;

  const onChangeView = () => {
    if (isAdvancedView) {
      changeElementSize(id || "", { height: 38 });
      setIsAdvancedView(false);
    } else {
      changeElementSize(id || "", { height: 211 });
      setIsAdvancedView(true);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Executive Summary</h3>
        {!hideSwitch && (
          <div className={styles.switch__container}>
            <Switch onChange={onChangeView} />
            <span className={styles.advansedSpan}>Advanced View</span>
          </div>
        )}
        {!isAdvancedView && (
          <button
            className={styles.setting__button}
            onClick={() => setIsOpenModal(true)}
          >
            <Setting />
          </button>
        )}
      </div>
      {isAdvancedView ? (
        <AdvancedView />
      ) : (
        <DefaultView
          choosenWidgets={customTitles ? selectedWidgetsCOO : selectedWidgets}
          customTitles={customTitles}
        />
      )}
      <SettingsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onApply={onApply}
        choosedWidgets={choosedWidgets}
        setChoosedWidgets={setChoosedWidgets}
        maxWidgets={MAXIMUM_WIDGETS}
        allowedGroups={allowedGroups}
      />
    </div>
  );
};
