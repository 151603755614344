import { Select } from "antd";
import { ADS_ANALITICS_ROUTES } from "src/router/routes";

export const { Option } = Select;

export const formatOptionLabel = ({ label = "", src = "" } = {}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      textTransform: "none",
      fontSize: "16px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}
  >
    {src && (
      <img
        src={src}
        alt={label}
        style={{ marginRight: 10, width: 24, height: 18 }}
      />
    )}
    {label}
  </div>
);

export const getDefaultCountry = (options) => {
  return options.find(
    (option) => option.label === "United States" || options[0],
  );
};

export const Routes = [
  {
    title: "Portfolios",
    link: ADS_ANALITICS_ROUTES.PORTFOLIO,
  },
  {
    title: "Campaigns",
    link: ADS_ANALITICS_ROUTES.CAMPAIGNS,
  },
  {
    title: "Products",
    link: ADS_ANALITICS_ROUTES.PRODUCTS,
  },
  {
    title: "Sankey",
    link: ADS_ANALITICS_ROUTES.SANKEY,
  },
  {
    title: "Heatmap",
    link: ADS_ANALITICS_ROUTES.HEATMAP,
  },
  {
    title: "Placement",
    link: ADS_ANALITICS_ROUTES.PLACEMENT,
  },
];

export const RoutesMarket = [
  {
    title: "Market Share",
    link: ADS_ANALITICS_ROUTES.PORTFOLIO,
  },
  {
    title: "Metrics Rate",
    link: ADS_ANALITICS_ROUTES.CAMPAIGNS,
  },
  {
    title: "ASIN Query Rate",
    link: ADS_ANALITICS_ROUTES.PRODUCTS,
  },
];
